import React from "react";
import "../styles/page.scss";

const Strive = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-9">
          <div class="front-page__text">
            
<h2 class="wp-block-heading">Welcome to the STRIVE Before Surgery Trial platform!</h2>



<p>Our research team is so glad you have made the decision to participate in our prehabilitation program (exercise and nutrition support before surgery!).</p>



<p>To help you prepare for surgery, your platform will give you access to instructions, videos, and exercise and nutrition live-sessions.</p>



<p>Our research team from The Ottawa Hospital will also phone you each week to help encourage you, modify or increase your exercises, discuss your protein powder intake, and to support you during this important journey!</p>



<p>To help track your progress and to support this research, we do kindly ask that you <strong>track your exercise and protein powder intake in the Tracking Log provided to you in your mailed package.</strong></p>



<p>Please always feel free to contact us if you have any questions!</p>



<p><strong>Phone: 1-877-372-7316</strong></p>
<p><strong>Email: <a href="mailto:strive@toh.ca">strive@toh.ca</a></strong></p>



<p><strong>Sincerely,</strong></p>
<p><strong>The STRIVE Before Surgery Trial Team</strong></p>



<figure class="wp-block-image size-medium"><img loading="lazy" decoding="async" width="300" height="120" src="https://strive.wellnesstoolbox.ca/wp-content/uploads/2023/05/Strive-Logo-Col-v1-300x120.png" alt="" class="wp-image-3249" srcset="https://strive.wellnesstoolbox.ca/wp-content/uploads/2023/05/Strive-Logo-Col-v1-300x120.png 300w, https://strive.wellnesstoolbox.ca/wp-content/uploads/2023/05/Strive-Logo-Col-v1-1024x410.png 1024w, https://strive.wellnesstoolbox.ca/wp-content/uploads/2023/05/Strive-Logo-Col-v1-768x307.png 768w, https://strive.wellnesstoolbox.ca/wp-content/uploads/2023/05/Strive-Logo-Col-v1.png 1417w" sizes="(max-width: 300px) 100vw, 300px" /></figure>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Strive;
